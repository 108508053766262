
import Head from "@/Molecules/Inertia/Head";
import {relativeRoute} from "@/Helpers/relativeRoute";
import Page from "@/Pages/Page";

export default function NotFound() {

  return (
    <Page title={'404 Pagina niet gevonden'}>

      <section className={'py-10'}>
        <div className="container mx-auto flex flex-col items-center">
          <h2>Deze pagina heeft het leven gelaten</h2>
          <p>Het lijkt erop dat de pagina die je zoekt niet (meer) bestaat. Maar geen zorgen! Hier zijn een paar
            suggesties om je weer op weg te helpen:

            <ul>
              <li><a href={relativeRoute('dashboard')}>Ga terug naar de homepage</a></li>
              {/*<li><a href={relativeRoute('overlijdensberichten')}>Zoek een overlijdensbericht</a></li>*/}
              {/*<li><a href={relativeRoute('contact')}>Neem contact op</a> als je denkt dat er iets mis is gegaan of als je hulp nodig hebt.</li>*/}
            </ul>
            We hopen dat je snel vindt wat je zoekt. Bedankt voor je bezoek aan Mensenlinq.<br />
            Groet, het Mensenlinq team
          </p>
          <a className={'btn mt-5'} href={relativeRoute('dashboard')}>Naar de homepagina</a>
        </div>
      </section>


    </Page>
  );
}
